import * as React from 'react'

import * as e from '@fe/components/Elements'
import * as c from '@fe/components/common/container'
import { menuUrl, homepageUrl } from '@fe/utils/redirect'

const ErrorPage404: React.SFC = () => (
  <c.Container>
    <e.Text.h3>
      oops, can’t find that page
      <span aria-label='investigate emoji' role='img'>
        🕵️‍♂️
      </span>
    </e.Text.h3>
    <e.Text.P>
      {'It looks like this page doesn’t exist anymore! Head back to our  '}
      <a href={menuUrl}>menu</a>
      {' or '}
      <a href={homepageUrl}>homepage</a>
      {' instead. 🤗'}
    </e.Text.P>
  </c.Container>
)

export default ErrorPage404
